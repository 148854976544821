import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from 'src/components/layout'
import Gallery from 'src/components/gallery'

export default ({ data }) => (
  <Layout>
    <div className="content">
      <Helmet>
        <title>
          Photo Gallery - Sunnylea Home Renovation No. 2 -{' '}
          {data.site.siteMetadata.title}
        </title>
      </Helmet>
      <h1>Photo Gallery - Sunnylea Home Renovation No. 2</h1>
      <Gallery data={data.allFile} />
    </div>
  </Layout>
)

export const query = graphql`
  query GallerySunnylea2 {
    site {
      siteMetadata {
        title
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: "gallery/gallery-sunnylea-2" } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      ...GalleryItems
    }
  }
`
